import { useState, useEffect } from 'react';
import { getCategories, CategoryDocument } from '@util/firestore/categories'

import Link from 'next/link';
import NavMenu from './NavMenu';
import NavMenuSection from './NavMenuSection';

const topLevelMenu = [
  { slug: 'digital-cameras', label: 'Cameras', href: '/c/digital-cameras' },
  { slug: 'photo-video-lenses', label: 'Lenses', href: '/c/photo-video-lenses' },
  { slug: 'video-camcorders', label: 'Video', href: '/c/video-camcorders' },
  { slug: 'vintage-camera-equipment', label: 'Vintage', href: '/c/vintage-camera-equipment' },
  { slug: 'lighting-studio', label: 'Lighting', href: '/c/lighting-studio' },
  { slug: 'drones-aerial-imaging', label: 'Drones', href: '/c/drones-aerial-imaging' },
  { slug: 'computers-electronics', label: 'Computers', href: '/c/computers-electronics' },
  { slug: 'photo-video-accessories', label: 'Accessories', href: '/c/photo-video-accessories' }
];

const CategoryBar = () => {
  const [categories, setCategories] = useState<CategoryDocument[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const allCategories = await getCategories(2);
        const level1Categories = allCategories.filter(cat => cat.level === 1);
        level1Categories.forEach(l1Cat => {
          l1Cat.sub_categories = allCategories.filter(cat => cat.parents?.includes(l1Cat.id));
        });
        setCategories(level1Categories);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="z-50 hidden h-[5rem] items-center justify-between gap-[1.6rem] border-b border-brand-lightest-gray bg-brand-white px-12 text-center font-medium text-black/[0.87] lg:flex [&>*]:text-[1.2rem]">
      <div className="flex whitespace-nowrap [&>*]:text-left">

        {/* Shop All Menu */}
        <NavMenu label="Shop All" href="/products">
          <div className="xl:columns-4 lg:columns-3 gap-[1.6rem] max-h-[80]">
            {categories.map((category) => (
              <NavMenuSection label={category.name} href={`/c/${category.slug}`} key={category.id}>
                {category.sub_categories?.map((subCat: CategoryDocument) => (
                  <Link className="hover:text-brand-secondary" href={`/c/${category.slug}/${subCat.slug}`} key={subCat.id}>
                    {subCat.name}
                  </Link>
                ))}
              </NavMenuSection>
            ))}
          </div>
        </NavMenu>

        {topLevelMenu.map(({ slug, label, href }) => {
          const category = categories.find(c => c.slug === slug);

          return (
            <NavMenu key={slug} label={label} href={href} orientation="vertical">
              <div className="flex flex-col w-full gap-[1.6rem]">
                {category?.sub_categories?.map((subCat) => (
                  <Link
                    key={subCat.id}
                    className="hover:text-brand-secondary"
                    href={`/c/${slug}/${subCat.slug}`}
                  >
                    {subCat.name}
                  </Link>
                ))}
              </div>
            </NavMenu>
          );
        })}

      </div>

      {/* Right-side Static Links */}
      <div className="flex whitespace-nowrap [&>*]:text-left">
        <Link className="text-[1.4rem] hover:text-brand-primary px-[0.9rem]" href="/brands">Brands</Link>

        <NavMenu label="Start Selling" href="/become-a-seller" orientation='vertical'>
          <div className="flex flex-col w-full gap-[1.6rem]">
            <Link className="hover:text-brand-secondary" href="/sellyourgear">Sell Your Gear</Link>
            <Link className="hover:text-brand-secondary" href="/sellyourgear#fees">Seller Fees</Link>
            <Link className="hover:text-brand-secondary" href="/about">Why Gear Focus?</Link>
            <Link className="hover:text-brand-secondary" href="/gearfocus-protection">Gear Focus Protection</Link>
          </div>
        </NavMenu>

        <NavMenu label="About Us" href="/help" orientation='vertical'>
          <div className="flex flex-col w-full gap-[1.6rem]">
            <Link className="hover:text-brand-secondary" href="/about">About Us</Link>
            <Link className="hover:text-brand-secondary" href="/contact-us">Contact</Link>
            <Link className="hover:text-brand-secondary" href="/faq">FAQ</Link>
            <Link className="hover:text-brand-secondary" href="/terms-and-conditions">Terms & Conditions</Link>
          </div>
        </NavMenu>

        <Link className="text-[1.4rem] px-[0.9rem] hover:text-brand-primary" href="/blog">Blog</Link>
        <Link className="text-[1.4rem] px-[0.9rem] hover:text-brand-primary" href="/videos">Videos</Link>
      </div>
    </div>
  );
};

export default CategoryBar;
