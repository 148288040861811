import { CloseIcon, FullLogo, MenuIcon, LoginIcon } from '@c/icons';
import Button from '@ui/Button';
import { searchClient } from '@util/getAlgolia';
import { isLargeDesktop } from '@util/index';
import { useAuth } from 'context/AuthContext';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useState } from 'react';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { PiUser } from "react-icons/pi";

import ShoppingCartButton from './icons/buttons/ShoppingCartButton';
import CategoryBar from './navigation/CategoryBar';

const BaseModal = dynamic(() => import('@c/modals/BaseModal'), { ssr: false });
const SearchBox = dynamic(() => import('@ui/SearchBox'), { ssr: false });
const UserMenu = dynamic(() => import('@c/menus/UserMenu'), { ssr: false });

interface NavBarProps {
  showCloseButton: boolean;
  onActionButtonClicked?: () => void;
}
const Navbar = ({ showCloseButton, onActionButtonClicked }: NavBarProps) => {
  const { user, userDoc } = useAuth();

  const [searchboxFocused, setSearchboxFocused] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const index = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'products' : 'listings'
  
  return (
    <InstantSearch searchClient={searchClient} indexName={index}>
      <div className="sticky top-0 z-50 w-full shadow-lg">
        {/* Top bar */}
        <div className="
          border-b border-black 
          top-0 z-40 flex 
          h-[8rem] w-full 
          items-center 
          bg-brand-white 
          px-4 md:px-12 
          lg:gap-[1.6rem]
        ">
          {/* Left nav items */}
          {/* desktop */}
          <div className="hidden lg:flex w-full items-center justify-between gap-8">
            <Link
              href="/"
              title="Gear Focus, Your New And Use Camera Marketplace"
              aria-label="brand link to homepage"
            >
              <FullLogo variant="default" width={257} height={53} />
            </Link>

            <SearchBox
              white={true}
              onBlur={() => setSearchboxFocused(false)}
              onFocus={() => setSearchboxFocused(true)}
            />
            <div className="grow text-h4 font-normal text-center min-w-[130px] leading-[15px]">
              <div className="font-normal text-[11px]">Call or Email</div>
              877-606-3504
              <div>
              <Link
                href="mailto:support@gearfocus.com"
                title="Email GearFocus for customer support"
                aria-label="Customer support email"
                className="font-normal text-[11px] text-brand-secondary underline"
              >
                support@gearfocus.com
              </Link>
              </div>
            </div>

            <ShoppingCartButton displayCount={true} />

            {user ? (
              <UserMenu />
            ) : (
              <div className="gap-[0.8rem]">
                <Link
                  href="/login"
                  className="flex flex-col items-center gap-1 text-brand-black hover:text-brand-primary"
                  aria-label="login and sign up"
                  title="Login / Sign up"
                >
                  <PiUser size={27} />
                  <span className="text-[1rem] hidden md:flex min-w-[69px]">Sign Up / Login</span>
                </Link>
              </div>
            )}

            <Button
              text="Sell your gear"
              title="Sell Your Used Camera Gear With Gear Focus"
              href={
                userDoc?.account_activated ? '/create-listing' : '/sellyourgear'
              }
              height="small"
            />
          </div>

          {/* mobile */}
          <div className="flex w-full items-center justify-between lg:hidden">
            {/* Left side: Menu + Logo */}
            <div className="flex items-center gap-4">
              <button
                type="button"
                aria-label="menu-trigger"
                onClick={onActionButtonClicked}
                className="text-brand-black"
              >
                {showCloseButton ? <CloseIcon /> : <MenuIcon />}
              </button>
              <Link
                aria-label="link to home"
                href="/"
                className="flex items-center"
              >
                <FullLogo variant="default" width={150} height={32} />
              </Link>
            </div>

            {/* Right side: Cart + User/Login */}
            <div className="flex items-center gap-3">
              <ShoppingCartButton displayCount={true} />
              {user ? (
                <UserMenu />
              ) : (
                <Link
                  href="/login"
                  className="flex flex-col items-center gap-1 text-brand-black hover:text-brand-primary"
                  aria-label="login and sign up"
                  title="Login / Sign up"
                >
                  <PiUser size={27} />
                </Link>
              )}
            </div>
          </div>
        </div>

        {/* Bottom bar */}
        <div className="p-[1rem] lg:hidden bg-brand-white">
          <SearchBox
            white={true}
            onBlur={() => setSearchboxFocused(false)}
            onFocus={() => setSearchboxFocused(true)}
          />
        </div>

        <CategoryBar />

        {/* Search Modal */}
        <BaseModal isOpen={searchModalOpen} hideCloseIcon isFullScreen>
          <div className="p-[1.6rem]">
            <SearchBox
              goBack={() => setSearchModalOpen(false)}
              searchIconRight
            />
          </div>
        </BaseModal>
      </div>
    </InstantSearch>
  );
};

export default Navbar;
