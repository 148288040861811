import {
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';

const NavMenu = ({
  orientation,
  label,
  href,
  children,
}: {
  orientation?: `vertical` | 'horizontal'
  label: string;
  href?: string;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  const { y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom',
  });

  const { getReferenceProps } = useInteractions([
    useHover(context, { handleClose: safePolygon() }),
    useDismiss(context, {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      referencePointerDown: true,
    }),
  ]);

  const variants = {
    open: { opacity: 1, height: 'auto', zIndex: 10 },
    closed: { opacity: 0, height: 0, zIndex: -1 },
  };

  return (
    <div onClick={() => setOpen(false)}>
      {href ? (
        <Link
          href={href}
          className="text-[1.4rem] hover:text-brand-primary px-[0.9rem]"
          ref={reference}
          {...getReferenceProps()}
        >
          {label}
        </Link>
      ) : (
        <span
          className="text-[1.4rem] hover:text-brand-primary px-[0.9rem]"
          ref={reference}
          {...getReferenceProps()}
        >
          {label}
        </span>
      )}
      <motion.div
        ref={floating}
        initial={variants.closed}
        animate={open ? 'open' : 'closed'}
        variants={variants}
        exit={{ height: 0 }}
        transition={{ duration: 0.2 }}
        style={{
          top: (y ?? 0) + 10,
          left: orientation === 'vertical' ? 'auto' : 0,
          position: strategy,
        }}
        className={`absolute ${orientation === 'vertical' ? '' : 'w-screen'} overflow-hidden border-t-4 border-brand-primary bg-white`}
      >
        <div className={`py-[1.6rem] ${orientation === 'vertical' ? 'px-[2.5rem]' : 'px-12'}`}>
          {children}
          </div>
      </motion.div>
      {open && (
        <div className="fixed left-0 top-[15rem] z-0 h-screen w-screen bg-black/50" />
      )}
    </div>
  );
};
export default NavMenu;
