import { useShoppingCart } from 'context/ShoppingCartContext';
import { PiShoppingCartSimple } from "react-icons/pi";

const ShoppingCartButton = ({ displayCount }: { displayCount?: boolean }) => {
  const { getItemCount, setCartOpen } = useShoppingCart();
  const itemCount = displayCount ? getItemCount() : undefined;
  const handleClick = () => setCartOpen((prev) => !prev);

  return (
    <button
      onClick={handleClick}
      className="relative flex text-brand-black hover:text-brand-primary"
      aria-label="shopping cart"
    >
      <PiShoppingCartSimple className="w-12 h-12 sm:w-14 sm:h-14" />
      {!!itemCount && itemCount > 0 && (
        <div className="absolute right-0 top-0 flex h-8 w-8 items-center justify-center rounded-full border-[.1rem] border-brand-white bg-brand-secondary text-[1.1rem] font-medium text-brand-white">
          {itemCount}
        </div>
      )}
      
    </button>
  );
};

export default ShoppingCartButton;
